<template>
  <div>
    <div class="row" style="background-color: white;">
      <div v-if="patient_data" class="col-12">
        <table style="width: 100%;">
          <thead>
            <tr v-if="p_protocolName" style="background-color: #19b9e9;">
              <th colspan="4">
                <span style="font-weight: bold; font-size: 15px;">Protokol Adı: {{ p_protocolName }}</span>
              </th>
            </tr>
            <tr style="background-color: #19b9e9;">
              <th colspan="4">
                <strong> Hasta Bilgileri </strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b> {{ $t('wdm16.3882') }}</b></td>
              <td> {{ w_presentation ? 'Hasta Ad Soyad' : patient_data.firstname + ' ' + patient_data.lastname}} </td>
              <td style="width: 25%;"><b> WisdomEra Dosya No </b></td>
              <td style="width: 25%;"> {{ w_presentation ? 'Dosya No' : patient_data.record_id }} </td>
            </tr>
            <tr>
              <td><b> {{ $t('wdm16.3757') }}</b></td>
              <td> {{ patient_data.sex === '0' ? $t('wdm16.9588') : $t('wdm16.4260') }} </td>
              <td><b> HBYS Dosya No </b></td>
              <td>
                <template v-if="w_presentation">HBYS Dosya No</template>
                <template v-else-if="patient_data.hospitals[user.hospital_group] && patient_data.hospitals[user.hospital_group][user.hospital_id] && patient_data.hospitals[user.hospital_group][user.hospital_id].data && patient_data.hospitals[user.hospital_group][user.hospital_id].data.id">{{ patient_data.hospitals[user.hospital_group][user.hospital_id].data.id }}</template>
                <template v-else> HBYS Kaydı Bulunamadı</template>
              </td>
              <!--  <td><b>City/Country</b></td>
              <td>
                <span v-if="patient_data.city_label">{{ w_presentation ? 'Şehir' : patient_data.city_label }}</span> /
                <span v-if="patient_data.country_label"> {{ w_presentation ? 'Ülke' : patient_data.country_label }}</span>
              </td> -->
            </tr>
            <tr>
              <td><b> {{ $t('wdm16.4589') }}</b></td>
              <td>{{ w_presentation ? 'Yaş' : patient_data.age }}</td>
              <td style="width: 25%;"><b> {{ patient_data.citizen_type === 'citizen' ? $t('wdm16.4148') : $t('wdm16.4088') }}</b></td>
              <td style="width: 25%;">{{ w_presentation ? 'Kimlik No' : patient_data.citizen_type === 'citizen' ? patient_data.personel_number : patient_data.passport_number }}</td>
            </tr>
            <tr>
              <td><b> {{ $t('wdm16.3822') }}</b></td>
              <td>{{ patient_data.last_bmi_data ? patient_data.last_bmi_data.weight : '' }}</td>
              <td><b> {{ $t('wdm16.3996') }}</b></td>
              <td>{{ patient_data.last_bmi_data ? patient_data.last_bmi_data.bsa : '' }}</td>
            </tr>
            <tr>
              <td><b>{{ $t('wdm16.3848') }}</b></td>
              <td>{{ patient_data.last_bmi_data ? patient_data.last_bmi_data.length : ''}}</td>
              <td><b>{{ $t('wdm16.3905') }}</b></td>
              <td v-if="patient_data.last_laboratory && patient_data.last_laboratory['2'] && patient_data.last_laboratory['2'].gfr">{{ patient_data.last_laboratory['2'].gfr }}</td>
              <td v-else>&nbsp;</td>
            </tr>
            <tr v-if="p_treatment">
              <td><b> Tanı </b></td>
              <td> {{ p_treatment.diagnosis.translation.tr }} </td>
              <td><b> Tedavi Sebebi </b></td>
              <td> {{ p_treatment.reason.label }} </td>
            </tr>
            <tr v-if="p_treatment">
              <td><b> Tedaviyi Başlatan </b></td>
              <td> {{ p_createdByName }} </td>
              <td><b> Tedavi Başlama Tarihi </b></td>
              <td> {{ p_treatment.started_at }} </td>
            </tr>
            <tr>
              <td><b> Ek Not </b></td>
              <td colspan="3">&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import moment from 'moment';

// /* global $ */

export default {
  name: 'PatientData',
  mounted () {},
  computed: mapGetters({
    lang: 'lang'
  }),
  props: {
    patient_data: {
      type: Object,
      required: false
    },
    p_protocolName: {
      type: String,
      required: false
    },
    p_createdByName: {
      type: String,
      required: false
    },
    p_treatment: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      user: {},
      w_presentation: false
    };
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
  },
  methods: {},
  watch: {},
  components: {}
};

</script>

<style>


</style>

