<template>
  <div>
    <b-card no-body>
      <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
        <b-button size="md" variant="warning" style="margin-right: 5px; padding: 0px;" @click="f_changeViewMode()">
          <template v-if="view_mode === 'table'">
            <img src="@/icon/1715140.png" title="Tablo Modu" style="width: 2.5em;" />
          </template>
          <template v-else-if="view_mode === 'sentence'">
            <img src="@/icon/3082142.png" title="Cümle Modu" style="width: 2.5em;" />
          </template>
          <template v-else-if="view_mode === 'polestar'">
            <img src="@/icon/2333399.png" title="Polestar Modu" style="width: 2.5em;" />
          </template>
          <template v-else-if="view_mode === 'wdm_specific'">
            <img src="@/icon/2956643.png" title="WDM Spesifik" style="width: 2.5em;" />
          </template>
        </b-button>
        <b-button class="pull-right" size="md" variant="secondary" @click="print()">
          <img src="@/icon/950780.png" title="Zaman Çizelgesi Modu Aktif" style="width: 2.5em;" />
        </b-button>
      </b-card-header>
      <div ref="report_here">
        <template v-if="p_patientData">
          <patient-data :patient_data="p_patientData"></patient-data>
          <hr>
        </template>
        <template v-for="x in p_wdmrList">
          <b-row style="margin: 0px; font-weight: bold; border-bottom: solid 1px">
            <b-col cols="12">{{ x.label }}</b-col>
          </b-row>
          <wisdom-data-show :change="change" :view_mode="view_mode" :data="x" :data_type="x.type" :option_data="p_optionDataObj[x.reference_wdm.key]"></wisdom-data-show>
          <hr>
          <hr>
        </template>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  default as PatientData
} from '@/components/widgets/PatientData';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import moment from 'moment';

export default {
  name: 'WdmrReport',
  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      lang: 'lang'
    })
  },
  props: {
    p_wdmrList: {
      type: Array,
      required: true
    },
    p_optionDataObj: {
      type: Object,
      required: true
    },
    p_patientData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      w_presentation: false,
      change: "0",
      view_mode: "table"
    };
  },
  created () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.all_users = JSON.parse(localStorage.getItem('all_users'));
  },
  methods: {
    f_changeViewMode: function () {
      if (this.view_mode === 'table') {
        this.view_mode = 'sentence';
      } else if (this.view_mode === 'sentence') {
        this.view_mode = 'polestar';
      } else if (this.view_mode === 'polestar') {
        this.view_mode = 'wdm_specific';
      } else if (this.view_mode === 'wdm_specific') {
        this.view_mode = 'table';
      }
    },
    print: function () {
      let x = new Date();
      let now_date = moment(x.toString()).format('DD/MM/YYYY HH:mm');
      let w = window.open('', '_blank', 'height=500,width=800,toolbar=0,location=0,menubar=0');
      let pageHtml = htmlTemplate
        .replace('##HEADER##', 'Data Dökümü')
        .replace('##BODY##', this.$refs.report_here.innerHTML)
        .replace('##FOOTER##', '');
        // .replace('##FOOTER##', 'Çıktıyı Alan: ' + this.user.first_name + ' ' + this.user.last_name + '<br />Çıktı Tarihi: ' + now_date + '<br />Bu çıktı yapay zeka sistemi WisdomEra (www.wisdomera.io) ile hazırlanmıştır.');
      w.document.write(pageHtml);
      w.document.close();
      w.onload = function () {
        w.focus();
        w.print();
      };
    }
  },
  watch: {},
  components: {
    PatientData,
    WisdomDataShow
  }
};

const htmlTemplate = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <title></title>
  <style type="text/css">
        @page {
        size: A4;
        margin: 10mm 8mm 10mm 8mm;
      }
    @media print {
      .drug_line {
          width: 100%;
          display: inline-block;
        }
      .col-sm-1 {width:8%;  float:left;}
      .col-sm-2 {width:16%; float:left;}
      .col-sm-3 {width:25%; float:left;}
      .col-sm-4 {width:33%; float:left;}
      .col-sm-5 {width:42%; float:left;}
      .col-sm-6 {width:50%; float:left;}
      .col-sm-7 {width:58%; float:left;}
      .col-sm-8 {width:66%; float:left;}
      .col-sm-9 {width:75%; float:left;}
      .col-sm-10{width:83%; float:left;}
      .col-sm-11{width:92%; float:left;}
      .col-sm-12{width:100%; float:left;}
      .col-1 {width:8%;  float:left;}
      .col-2 {width:16%; float:left;}
      .col-3 {width:25%; float:left;}
      .col-4 {width:33%; float:left;}
      .col-5 {width:42%; float:left;}
      .col-6 {width:50%; float:left;}
      .col-7 {width:58%; float:left;}
      .col-8 {width:66%; float:left;}
      .col-9 {width:75%; float:left;}
      .col-10{width:83%; float:left;}
      .col-11{width:92%; float:left;}
      .col-12{width:100%; float:left;}

      html, body {
        width: 210mm;
        background: white!important;
        height: 297mm;
      }
      .page-header {
        
      }
      .page-footer {
        position: fixed;
        bottom: 0;
      }
      .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
      }
    }
    .page-footer {
      font-size: 8px;
      position: fixed;
      bottom: 0;
    }
    .text-right {
      text-align: right;
    }
    table, td, tr {
      border: 1px solid black;
    }
    table {
      width: 100%;
    }
    .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
    }
  .row {
    flex-wrap: wrap;
    display: flex;
  }
    .wisdom-show-row {
      margin: 0px;
      border-bottom: solid 0.5px #d9d4d4;
    }

    .wisdom-show-row:hover {
      margin: 0px;
      border-bottom: solid 0.5px #d9d4d4;
      background-color: rgba(142, 243, 124, 0.65);
    }

    .level-1-param {
      padding-left: 10px;
      margin-left: 10px;
    }

    .level-2-param {
      padding-left: 20px;
      border-left: dotted 1px #20a8d8;
      border-bottom: dotted 1px #20a8d8;
      margin-left: 30px;
    }

    .level-3-param {
      padding-left: 20px;
      border-left: dotted 1px green;
      border-bottom: dotted 1px green;
      margin-left: 60px;
    }

    .level-4-param {
      padding-left: 20px;
      border-left: dotted 1px #c05353;
      border-bottom: dotted 1px #c05353;
      margin-left: 90px;
    }

    .level-2-list {
      padding-left: 10px;
      margin-left: 10px;
    }

    .level-3-list {
      padding-left: 20px;
      border-left: dotted 1px #20a8d8;
      border-bottom: dotted 1px #20a8d8;
      margin-left: 30px;
    }

    .level-4-list {
      padding-left: 20px;
      border-left: dotted 1px green;
      border-bottom: dotted 1px green;
      margin-left: 60px;
    }

    .level-1-detail {
      padding-left: 10px;
      border-left: 1px solid black;
      border-bottom: solid 1px blue;
      margin-left: 20px;
    }

    .level-2-detail {
      padding-left: 20px;
      border-left: 1px solid black;
      border-bottom: solid 1px blue;
      margin-left: 20px;
    }

    .level-3-detail {
      padding-left: 20px;
      border-left: dotted 1px #20a8d8;
      border-bottom: dotted 1px #20a8d8;
      margin-left: 30px;
    }

    .level-4-detail {
      padding-left: 20px;
      border-left: dotted 1px green;
      border-bottom: dotted 1px green;
      margin-left: 60px;
    }

    .level-5-detail {
      padding-left: 20px;
      border-left: dotted 1px #c05353;
      border-bottom: dotted 1px #c05353;
      margin-left: 90px;
    }

    .level-1-param-caption {
      color: black;
    }

    .level-2-param-caption {
      color: #20a8d8;
    }

    .level-3-param-caption {
      color: green;
    }

    .level-4-param-caption {
      color: #c05353;
    }

    .level-3-list-caption {
      color: #20a8d8;
    }

    .level-4-list-caption {
      color: green;
    }

    .level-3-detail-caption {
      color: #20a8d8;
    }

    .level-4-detail-caption {
      color: green;
    }

    .level-5-detail-caption {
      color: #c05353;
    }

    .level-1-option {
      color: black;
      white-space: pre-line;
    }

    .level-2-option {
      color: #20a8d8;
      white-space: pre-line;
    }

    .level-3-option {
      color: green;
      white-space: pre-line;
    }

    .level-4-option {
      color: #c05353;
      white-space: pre-line;
    }

    .clb-value {
      color: red;
      font-weight: bold;
    }

    .cub-value {
      color: red;
      font-weight: bold;
    }

    .lb-value {
      color: red;
      font-weight: bold;
    }

    .ub-value {
      color: red;
      font-weight: bold;
    }

    .wdm_specific_polestar_table {
      padding-left: 35px;
      margin-left: 35px;
      border: solid 1px #c3d8d8;
      width: calc(100% - 35px);
    }

    .wdm_specific_polestar_tr {
      border: solid 1px #c3d8d8;
    }

    .wdm_specific_polestar_td {
      border: solid 1px #c3d8d8;
    }

    .wdm_specific_polestar_th {
      border: solid 1px #c3d8d8;
    }
    ##CSS##
  </style>

</head>
<body>
  <div class="page-header">
    ##HEADER##
  </div>
  <div class="page-body">
    ##BODY##
  </div>
  <div class="page-footer">
    ##FOOTER##
  </div>
</body>
</html>
`;

</script>

<style type="text/css"></style>

