<template>
  <div>
    <b-card style="min-height: 750px;">
      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
        <i class="fa fa-file-text-o"></i> Webservis Listesi Ekranı
        <b-button variant="warning" size="sm" class="pull-right" @click="add_new_wisdom_data('wdm23')"><i class="fa fa-save"></i> Yeni Webservis Tanımla </b-button>
      </b-card-header>
      <b-row>
        <b-col cols="6">
          <b-card style="min-height: 750px;">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> Webservis Listesi
            </b-card-header>
            <b-row>
              <template v-for="wdm23 in d_wdm23List">
                <b-col style="cursor: pointer;" @click="f_getWdm23Detail(wdm23.value)" :key="wdm23.value" :style="wdm23.value === d_selectedWdm23Key ? 'background: green;' : ''" cols="12">
                  {{ wdm23.label }} </b-col>
              </template>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card style="min-height: 750px;">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> Webservis Detayı
              <b-dropdown class="pull-right" variant="primary" size="sm">
                <template slot="button-content"> Raporlama İşlemleri</template>
                <b-dropdown-item :disabled="d_selectedWdm23Key === '' ? true : false" size="sm" @click="f_reportThisWdm23()"><i class="fa fa-print"></i> Bu Veriyi Raporla </b-dropdown-item>
                <b-dropdown-item :disabled="d_wdm23List.length > 0 ? false : true" size="sm" @click="f_reportAllWdm23()"><i class="fa fa-print"></i> Tüm Verileri Raporla </b-dropdown-item>
              </b-dropdown>
              <b-button :disabled="d_selectedWdm23Key === '' ? true : false" variant="warning" size="sm" class="pull-right" @click="f_editWdm23()"><i class="fa fa-edit"></i> Veriyi Düzenle </b-button>
            </b-card-header>
            <b-row>
              <b-col v-if="d_showWdm23Detail" cols="12">
                <wisdom-data-show :change="change" :view_mode="'table'" :data="d_wdm23Detail" :data_type="data_type" :option_data="option_data['wdm23']"></wisdom-data-show>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'1500'">
      <h3 slot="header"> Webservis Bilgisi Ekleme ve Düzenleme Ekranı </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="wisdomDataSave()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showWdmrReportingModal" @close="d_showWdmrReportingModal = false" :width="'1500'">
      <h3 slot="header">
        Rapor Listeleme Ekranı
      </h3>
      <div slot="body">
        <wdmr-report :p_wdmrList="d_wdmrReportData.wisdom_data" :p_optionDataObj="d_wdmrReportData.option_data"></wdmr-report>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showWdmrReportingModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as WdmrReport
} from '@/components/widgets/WdmrReport';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { mapGetters } from 'vuex';
import OptionService from '@/services/option';
import { ClsWdmrForever } from "@/services/public/wdm";
import GlobalService from "@/services/global";
export default {
  name: 'SystemWebservices',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    WdmrReport,
    WisdomDataShow,
    WisdomDataModal,
    Modal
  },
  props: {},
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      change: "0",
      d_wdm23List: [],
      d_wdm23Detail: {},
      d_selectedWdm23Key: '',
      data_type: '23',
      option_data: {},
      wisdom_data: {},
      d_wdmrReportData: {},
      show_wisdom_data_modal: false,
      d_showWdmrReportingModal: false,
      wisdom_op: 'new',
      d_showWdm23Detail: false
    };
  },
  created: function () {
    let query = 'data_type=wdm23&location=wisdom';
    this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'WDM23 Yükleniyor' } });
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wdm23'] = resp.data.result;
        } else {
          console.log('errror ', resp.data.message);
        }
      });
    this.f_getAllWdm23List();
  },
  mounted: function () {},
  methods: {
    f_reportAllWdm23: function () {
      this.d_wdmrReportData.wisdom_data = [];
      this.d_wdmrReportData.option_data = {};
      let key_list = []
      for (let i in this.d_wdm23List) {
        key_list.push(this.d_wdm23List[i].value);
      }
      console.log('130', key_list);
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'WDM23 Listesi Getiriliyor' } });
      GlobalService.get_multiple_cb_documents({ 'key_list': key_list, 'bucket': 'option' })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            console.log('136', resp.data.result);
            for (let k in resp.data.result) {
              this.d_wdmrReportData.wisdom_data.push(resp.data.result[k]);
            }
            let key = this.d_wdmrReportData.wisdom_data[0].reference_wdm.key;
            this.d_wdmrReportData.option_data[key] = JSON.parse(JSON.stringify(this.option_data['wdm23']));
            this.d_showWdmrReportingModal = true;
          } else {
            alert('error ', resp.data.message);
          }
        });
      // this.d_wdmrReportData.wisdom_data.push(JSON.parse(JSON.stringify(this.d_wdm23Detail)));
    },
    f_reportThisWdm23: function () {
      this.d_wdmrReportData.wisdom_data = [];
      this.d_wdmrReportData.option_data = {};
      this.d_wdmrReportData.wisdom_data.push(JSON.parse(JSON.stringify(this.d_wdm23Detail)));
      let key = this.d_wdm23Detail.reference_wdm.key;
      this.d_wdmrReportData.option_data[key] = JSON.parse(JSON.stringify(this.option_data['wdm23']));
      this.d_showWdmrReportingModal = true;
    },
    f_getAllWdm23List: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'WDM23 Listesi Getiriliyor' } });
      GlobalService.get_one_cb_document({ 'key': 'wdm23_record_keys_list', 'bucket': 'option' })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_wdm23List = resp.data.result.list;
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    f_getWdm23Detail: function (value) {
      this.d_selectedWdm23Key = value;
      this.d_showWdm23Detail = false;
      this.d_wdm23Detail = {};
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'WDM23 Datası Getiriliyor' } });
      GlobalService.get_one_cb_document({ 'key': this.d_selectedWdm23Key, 'bucket': 'option' })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status === 'success') {
            this.d_showWdm23Detail = true;
            this.d_wdm23Detail = resp.data.result;
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    f_editWdm23: function () {
      this.wisdom_op = "edit";
      this.data_type = 'wdm23';
      this.wisdom_data = JSON.parse(JSON.stringify(this.d_wdm23Detail));
      this.show_wisdom_data_modal = true;
    },
    add_new_wisdom_data: function (data_type) {
      this.data_type = data_type;
      this.wisdom_op = "new";
      let pre_data = { 'id': 'new', 'data': {}, 'type': data_type, 'reference_wdm': {}, 'parent': data_type };
      pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
      if (this.option_data[this.data_type]['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
      }
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        pre_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      this.wisdom_data = pre_data;
      this.show_wisdom_data_modal = true;
    },
    wisdomDataSave: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let wdmr_name = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      this.wisdom_data['label'] = wdmr_name;
      data = { 'data': this.wisdom_data };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'WDM23  Kayıt Ediliyor' } });
      OptionService.save_wdm_option(data).then(resp => {
        this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        this.f_getAllWdm23List();
        if (this.wisdom_op === 'edit') {
          this.f_getWdm23Detail(this.d_selectedWdm23Key);
        }
        this.$forceUpdate();
        this.show_wisdom_data_modal = false;
      });
    }
  },
  watch: {}
};

</script>

<style></style>

